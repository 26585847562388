import React from 'react';
import IntelliProgressBar from '../../../components/IntelliProgressBar';
import css from './style.scss';

const IntelligenceValueCell = ({ name, value, icon, addClass = '', score_percent = 0 }) => {
    // Determine the percentage based on the value
    let percent = 0;
    if (value === 'Good') {
        percent = 65;
    } else if (value === 'Average') {
        percent = 42;
    } else if (value === 'Excellent') {
        percent = 80;
    } else if (value === 'Luxury') {
        percent = 93;
    } else if (value === 'Poor') {
        percent = 20;
    } else if (value === 'Disrepair') {
        percent = 10;
    } else {
        percent = 0; // default percentage if value is not matched
    }

    return (
        <div className={`${css.value} ${addClass}`}>
            <div className={css.IntelligenceValueCellDiv}>
                <div className={css.IntelligenceCellIcon}>
                    <img className={css.IntelligenceCellImg} src={icon} alt={name} />
                </div>
                <div>
                    <div className={css.name}>{name}</div>
                    <div className={`${css.scorelabel} ${score_percent > 0 ? css.additionalClass : ''}`}>
                        {value || 'N/A'}
                        {score_percent > 0 && (
                            <>
                                <div className={css.bar}></div>
                                <span className={css.percentLabel}>
                                    {`${score_percent}%`}
                                </span>
                            </>
                        )}
                    </div>
                    <IntelliProgressBar percent={score_percent > 0 ? score_percent : percent} size={'small'} />
                </div>
            </div>

        </div>
    );
};

export default IntelligenceValueCell;