import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import css from '../style.scss';
import AutoSearchDropdown from './index';

const FilterComboBox = ({ boxTittle, minOptions, minLabel, maxOptions, maxLabel, onSelectValue, defaultValues, headerClassName, isHighlightIndex }) => {
  const [minValue, setMinValue] = useState(defaultValues.min);
  const [maxValue, setMaxValue] = useState(defaultValues.max);

  const onSelectedValue = (type, value) => {
    if (type === 'min' && value !== minValue) {
      setMinValue(value);
      onSelectValue(value, maxValue, 'min');
    } else if (type === 'max' && value !== maxValue) {
      setMaxValue(value);
      onSelectValue(minValue, value, 'max');
    }
  };

  useEffect(() => {
    if (defaultValues.min !== minValue) {
      setMinValue(defaultValues.min);
    }
    if (defaultValues.max !== maxValue) {
      setMaxValue(defaultValues.max);
    }
  }, [defaultValues]);

  return (
    <>
      <h4 className={classNames((headerClassName === "" ? css.clsSubHeading: headerClassName), (isHighlightIndex ? css.highlightOrange : ""))}>{boxTittle}</h4>
      <div className={classNames(css.flexBoxNowrap, css.clsFilterSelect)}>
        <AutoSearchDropdown
          id={uuidv4()}
          items={minOptions}
          placeHolder={minLabel}
          defaultValue={minValue}
          onChangeValue={(value) => onSelectedValue('min', value)}
        />
        <AutoSearchDropdown
          id={uuidv4()}
          items={maxOptions}
          placeHolder={maxLabel}
          defaultValue={maxValue}
          onChangeValue={(value) => onSelectedValue('max', value)}
        />
      </div>
    </>
  );
};

export default FilterComboBox;
