import SuggestedInput from 'components/SuggestedInput';
import Button from "components/base/Button";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from '../../../../../assets/images/search-icon.svg';
import SearchWhiteIcon from '../../../../../assets/images/search-white-color-icon.svg';
import newsearchfilter from "../SearchFilterNew.scss";
import { useActiveSection } from "./ActiveSectionContext";
import { LeadList } from "./right/LeadList";
import LiensBankruptcy from "./right/LiensBankruptcy";
import MLSscreen from "./right/MLS";
import MortgageInfo from "./right/MortgageInfo";
import OwnerInformationOccupancy from "./right/OwnerInformationOccupancy";
import PreForeclosureBank from "./right/PreForeclosureBank";
import PropertyDetails from "./right/PropertyDetails";
import ValueEquityScreen from "./right/ValueEquity";
import FilterSearchBar from '../FloatingSearchBar';
import PropstreamIntelligenceScreen from './right/PropstremIntelligence';

import {
  clearSuggestion,
  countyMap,
  fipsMap,
  searchSuggestions,
  selectSuggestion,
  setRecentSuggestions,
  setSuggestionLabel,
  setSuggestionValue,
  setSuggestions,
} from 'data/search';


const RECENT_SUGGESTION_PATH = 'PropStream.RecentSuggestions.1';
const MAX_RECENT_SUGGESTIONS = 15;

export const RightPanel = ({ selectedIndex }) => {
  const components = [
    LeadList,
    PropertyDetails,
    PropstreamIntelligenceScreen,
    MLSscreen,
    PreForeclosureBank,
    OwnerInformationOccupancy,
    LiensBankruptcy,
    ValueEquityScreen,
    MortgageInfo
  ];
  const { activeSection, setActiveSection } = useActiveSection();
  const containerRef = useRef(null);
  const suggestedInputRef = useRef(null);
  const [isInputFocused, setIsInputFocused] = useState(true);
  const [isInputClickedOrTagSelected, setIsInputClickedOrTagSelected] = useState(false);


  const [searchItem, setSearchItem] = useState('');
  const [searchTimer, setSearchTimer] = useState(null);
  const [isHidden, setIsHidden] = useState(true);



  const suggestion = useSelector(state => selectSuggestion(state)).toJS();
  const dispatch = useDispatch();

  const handleScroll = () => {
    const container = containerRef.current;
    if (!container) return;

    const containerTop = container.getBoundingClientRect().top;

    const scrollPosition = container.scrollTop;

    if (scrollPosition > 80) {
      setIsHidden(false)
    } else {
      setIsHidden(true)
    }

    const sectionIndexes = components.map((_, index) => {
      const ref = refsArray[index].current;
      if (ref) {
        const rect = ref.getBoundingClientRect();
        return {
          index,
          top: rect.top - containerTop,
          bottom: rect.bottom - containerTop
        };
      }
      return null;
    });

    const visibleSections = sectionIndexes.filter(({ top, bottom }) => {
      return top <= container.clientHeight && bottom > 100;
    });

    if (visibleSections.length > 0) {
      setActiveSection(visibleSections[0].index);
    }
  };

  useEffect(() => {
    handleScroll();
  }, []);



  const refsArray = components.map(() => useRef(null));

  const scrollToSection = () => {
    const selectedRef = refsArray[selectedIndex].current;
    const container = containerRef.current;

    if (selectedRef && container) {
      const containerRect = container.getBoundingClientRect();
      const selectedRect = selectedRef.getBoundingClientRect();
      const scrollTopValue =
        selectedRect.top - containerRect.top + container.scrollTop;

      container.scrollTo({
        top: scrollTopValue,
        behavior: "smooth"
      });
    }
  };

  const focusSuggestedInput = () => {
    if (suggestedInputRef.current) {
      suggestedInputRef.current.focus();
      suggestedInputRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };

  useEffect(() => {
    scrollToSection();
  }, [selectedIndex]);

  const handleChangeSuggestion = (item) => {

    setSearchItem(item)

    if (document.activeElement && document.activeElement.blur) document.activeElement.blur();

    const loc = item;
    if (loc) {
      const { type, cityId, id } = loc;

      if (type === 'P') loc.propertyId = id;
      else if (!cityId && type === 'C') loc.cityId = id;

      dispatch(setRecentSuggestions(setRecentSuggestion(loc)));
    }

    dispatch(setSuggestionValue(loc));
  }

  const setRecentSuggestion = (item) => {
    let items = [];

    try {
      items = getRecentSuggestions();

      const key = (item.label || '').trim().toLowerCase();

      if (key !== '') {
        items = items.filter(s => s.key !== key);
        items.unshift({ ...item, key });
        items.length = Math.min(items.length, MAX_RECENT_SUGGESTIONS);

        window.localStorage.setItem(RECENT_SUGGESTION_PATH, JSON.stringify(items));
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
      throw e;
    }

    return items;
  };

  const getRecentSuggestions = () => {
    try {
      return JSON.parse(window.localStorage.getItem(RECENT_SUGGESTION_PATH)).filter(i => !!i && !!i.label);
    } catch (e) {
      return [];
    }
  };

  const handleFetchSuggestion = (query) => {

    dispatch(setSuggestionLabel(query));

    if (searchTimer) {
      clearTimeout(searchTimer);
      setSearchTimer(null)
    }

    if (query && query.length >= 5) {

      setSearchTimer(setTimeout(() => {



        const results = parseSuggestion(query);

        if (results.length) dispatch(setSuggestions(results));
        else dispatch(searchSuggestions(query));
      }, 800))

    }

    return Promise.resolve([]);
  }

  // TODO: This should not exist, and APN suggestions should come from the server. County ID should be used instead of FIPS.
  const parseSuggestion = (query) => {
    let localResults = [];

    /**
     * Check for county matches. Allow:
     * Orange County, CA (County Only)
     * #93974065, Orange County, CA (APN + County)
     * 06059; (FIPS Only)
     * 06059;93974065
     *
     * Use local results only if this is a FIPS and/or APN search
     */
    if (query !== '') {
      // Check for FIPS pattern
      let countyQuery = query;
      let match = /^(\d{5})#([a-z0-9\s-\s.]+)?$/i.exec(query);

      if (match && match[1]) {
        // Convert to "<apn>, <county>, <state> format
        const apn = match[2] && match[2].trim();
        const county = fipsMap[match[1]];
        if (county) countyQuery = `${apn ? `#${apn}, ` : ''}${county.search}, ${county.state}`;
      }

      match = /^((((apn)?#)([a-z0-9\s-\s.]+)),)?([a-z\s'-]+)(,\s*([a-z]{2}))?$/i.exec(countyQuery) || [];
      const stateMatch = match[8];
      const countyMatch = match[6] || '';
      const apn = match[5] ? match[5].trim() : null;

      if (stateMatch && countyMatch.length > 3 && apn) {
        const search = countyMatch.toLowerCase().replace(/[^a-z]+/g, ' ').replace(/\s+county$/, '').trim();
        const explicitCountySearch = countyMatch.toLowerCase().includes(' county');

        // Pull all matching counties for respective state. Ignore independent cities / non-counties unless "county" is in the search string OR APN is provided.
        // If neither of those is true then the independent city should already be in the Bing results.
        localResults = localResults.concat((countyMap[stateMatch.toUpperCase()] || []).filter(c => c.search.includes(search) && (c.county || explicitCountySearch || apn)).map((county) => {
          const label = `${apn ? `APN# ${apn}, ` : ''}${county.name}`;
          return { label, fips: county.fips, apn };
        }));
      }
    }

    return localResults;
  };

  const handleClearSuggestion = () => {
    dispatch(clearSuggestion());

    setSearchItem('')

  }
  
  return (
    <div className={newsearchfilter.clsRightBodyScroll} ref={containerRef} onScroll={handleScroll}>
      <div ref={refsArray[0]}>
        {/* Latest Tag Search Bar */}
        {/* <div className={newsearchfilter.dropdownTagHeader}> */}
        <div className={isInputClickedOrTagSelected ? newsearchfilter.dropdownTagHeader : newsearchfilter.dropdownTagHeaderRight}>
          <div className={newsearchfilter.clsTopHeader}>
            {/* <FilterSearchBar /> */}
            <FilterSearchBar setIsInputClickedOrTagSelected={setIsInputClickedOrTagSelected} />
          </div>
        </div>
        <div className={newsearchfilter.topPadding}>
          <LeadList />
        </div>
      </div>
      <div ref={refsArray[1]}>
        <PropertyDetails />
      </div>
      <div ref={refsArray[2]}>
        <PropstreamIntelligenceScreen />
      </div>
      <div ref={refsArray[3]}>
        <MLSscreen />
      </div>
      <div ref={refsArray[4]}>
        <PreForeclosureBank />
      </div>
      <div ref={refsArray[5]}>
        <OwnerInformationOccupancy />
      </div>
      <div ref={refsArray[6]}>
        <LiensBankruptcy />
      </div>
      <div ref={refsArray[7]}>
        <ValueEquityScreen />
      </div>
      <div ref={refsArray[8]}>
        <MortgageInfo />
      </div>
    </div>
  );
};

RightPanel.propTypes = {
  selectedIndex: PropTypes.number.isRequired
};
