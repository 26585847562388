import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import SearchArrow from '../../../../../../assets/images/select-down-arrow.svg';
import SearchUpArrow from '../../../../../../assets/images/select-up-arrow.svg';
import css from "../style.scss";
import { v4 as uuidv4 } from 'uuid';

const AutoSearchDropdown = ({ items, placeHolder, onChangeValue , defaultValue , searchDisabled = false }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  

  const [options, setOptions] = useState(items ? items : []);
  const InputContainerRef = useRef(null);

  const inputRef = useRef(null); // Create a ref for the input element


  let typingTimeout;

  useEffect(() => {
    // console.log(defaultValue)
    setSearchTerm(defaultValue || '');
  }, [defaultValue]);


  const handleInputChange = e => {
    if (!searchDisabled) { // Check if input is not disabled
      let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
      setSearchTerm(value);

      const searchValue = value.toLowerCase();
      const filteredSuggestions = items?.filter(item =>
        String(item?.name)?.toLowerCase()?.includes(searchValue)
      );

      if (filteredSuggestions?.length) {
        setOptions(filteredSuggestions);
      } else {
        setIsFocused(false);
      }

      // Clear any previous timeout
      clearTimeout(typingTimeout);

      // Set a new timeout to call onChangeValue after 1 second of stopping typing
      typingTimeout = setTimeout(() => {
        onChangeValue(value);
      }, 1000);
    }
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };



  const handleOutsideClick = e => {
    if (InputContainerRef.current && !InputContainerRef.current.contains(e.target)) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);


  const optionSelected = (option) => {
    setSearchTerm(option.value);
    setIsFocused(false);
    onChangeValue(option.value);
  }

  const dropdownToggle = (ev) => {
    ev.stopPropagation();
    setIsFocused(!isFocused);
  
    if (!isFocused) {
      if (inputRef?.current) {
        inputRef?.current.focus();
      }
    }
  };



  return (
    <div className={classNames(css.clsSearchBox)} ref={InputContainerRef}>
      <input
        type="text"
        placeholder={placeHolder ? placeHolder  : 'select'}
        className={isFocused ? classNames(css.clsSearchInput, css.clsSearchInputActive) : css.clsSearchInput}
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        ref={inputRef}
        readOnly={searchDisabled}
        id={'newuifilterRef'+ uuidv4()}
      />
      <span className={classNames(css.clsAutoSearchArrow, css.clsAutoSearchRotate)}>
        <ReactSVG
          src={isFocused ? SearchUpArrow : SearchArrow}
          className={css.svgArrowIcon}
          onClick={dropdownToggle}
        />
      </span>

      {isFocused && (
        <div className={css.clsAutoSearchDropdown}>
          <ul className={css.clsAutoSearchList}>
            {options &&
              options.map((option, index) => (
                <li
                id={'newuifilterRef'+ uuidv4()}
                  key={index}
                  className={css.clsAutoSearchOption}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    optionSelected(option)
                  }}
                >
                  {option.label}
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AutoSearchDropdown;