import React, { useState, useRef, useEffect } from "react";
import newsearchfilter from "./SearchFilterNew.scss";
import { useFilter } from "./TagFilterContext";
import Button from "components/base/Button";
import { ReactSVG } from "react-svg";
import SearchImageIcon from '../../../../assets/images/search-input-icon.svg'
import CloseBlueIcon from '../../../../assets/images/close-btn-blue.svg'
import ArrowUpBlueIcon from '../../../../assets/images/arrow-up-blue.svg'
import ArrowDownBlueIcon from '../../../../assets/images/arrow-down-blue.svg'
import { tagSuggestions } from "./shared/ConstantsNew";
import { capitalizeFirstLetter, normalizeString } from "../../../../utils/filter_menu";

const FilterSearchBar = ({ setIsInputClickedOrTagSelected }) => {
    const { addTag, selectedTags, removeTag, clearTags, countTagOccurrences, navigateUp, navigateDown, navIndex } = useFilter();
    const [tagSearchTerm, setTagSearchTerm] = useState("");
    const [isTagFocused, setIsTagFocused] = useState(false);
    const [isInputClicked, setIsInputClicked] = useState(false);
    const [count, setCount] = useState(0);
    const [indexFocus, setIndexFocus] = useState(false);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
    const [tagSelected, setTagSelected] = useState(false);

    const searchRef = useRef(null);
    const inputRef = useRef(null);
    const suggestionsRef = useRef([]);

    const handleChange = (e) => {
        setTagSearchTerm(e.target.value);
        setIsTagFocused(true);
        setIndexFocus(false)
        const value = e.target.value;
        setIsInputClicked(true);
        setIsInputClickedOrTagSelected(true);
        if (value === "") {
            clearTags();
            setIsTagFocused(false);
            // setIsInputClicked(false);
            setCount(0);
            setTagSelected(false);
        }
    };

    const handleClearClick = () => {
        setTagSearchTerm("");
        setIsTagFocused(false);
        setIsInputClicked(false);
        setIndexFocus(false)
        clearTags()
        setTagSelected(false);
        setIsInputClickedOrTagSelected(false);
    };

    const handleRemoveTag = (tag) => {
        removeTag(tag);
    };

    const handleTagClick = (tag) => {
        clearTags()
        addTag(tag);
        setIndexFocus(true)
        setTagSearchTerm(tag);
        setIsTagFocused(false);
        setIsInputClicked(false);
        setCount(countTagOccurrences(tag))
        setTagSelected(true);
        setIsInputClickedOrTagSelected(true);
    };

    const handleClickOutsideTag = (event) => {

        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setIsTagFocused(false);
            setIsInputClicked(false);
            if(tagSearchTerm === ""){
                setIsInputClickedOrTagSelected(false);
            }
        }
        
        
    };

    const filteredSuggestions = tagSuggestions
        .filter(tag =>
            normalizeString(tag).includes(normalizeString(tagSearchTerm.replace("dead", "deceased").replace("death", "deceased")))
        );
    
        const sortedSuggestions = filteredSuggestions.sort((a, b) => {
            const countA = countTagOccurrences(a);
            const countB = countTagOccurrences(b);
            if (countA !== countB) {
                return countA - countB;
            } else {
                return a.localeCompare(b);
            }
        });

        const handleKeyDown = (e) => {
            const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);
            if ((isMac && e.metaKey && e.key === 'f') || (!isMac && e.ctrlKey && e.key === 'f')) {
                e.preventDefault();
                setIsTagFocused(true);
                setIsInputClicked(true);
                inputRef.current.focus();
                setIsInputClickedOrTagSelected(true);
            } else if (tagSelected && e.key === 'Enter') {
                e.preventDefault();
                navigateDown();
            } else if (filteredSuggestions.length > 0) {
                if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    if (activeSuggestionIndex < filteredSuggestions.length - 1) {
                        setActiveSuggestionIndex(activeSuggestionIndex + 1);
                        suggestionsRef.current[activeSuggestionIndex + 1]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                    }
                } else if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    if (activeSuggestionIndex > 0) {
                        setActiveSuggestionIndex(activeSuggestionIndex - 1);
                        suggestionsRef.current[activeSuggestionIndex - 1]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                    }
                } else if (e.key === 'Enter' && activeSuggestionIndex >= 0) {
                    handleTagClick(filteredSuggestions[activeSuggestionIndex]);
                }
            }
        };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideTag);
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideTag);
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [activeSuggestionIndex, filteredSuggestions, tagSelected]);


    return (
        <div id="searchBarRef" className={newsearchfilter.clsSearchBox} ref={searchRef}>
            <div className={newsearchfilter.clsSearchInput}>
                <div className={newsearchfilter.clsSearchInputOuterDiv}>
                    <div className={newsearchfilter.sidePadding_16 + (isInputClicked ? ' ' + newsearchfilter.clsSearchInputFocus : '') + (isTagFocused && tagSearchTerm.length > 1 ? ' ' + newsearchfilter.clsRemoveBottomBoder : '')}>
                        {/* <div className={newsearchfilter.clsSearchInputInnerDiv} style={{ backgroundColor: isInputClicked ? "red" : "transparent" }}> */}
                        <div className={newsearchfilter.clsSearchInputInnerDiv}>
                            <div>
                                <img src={SearchImageIcon}></img>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder={isInputClicked ? "" : "Find a Filter"}
                                    // placeho/lder="Find a Filter"
                                    // className={newsearchfilter.clsSearchInputField}
                                    className={newsearchfilter.clsSearchInputField + (isInputClicked ? ' ' + newsearchfilter.clsNoneBg : '')}
                                    value={capitalizeFirstLetter(tagSearchTerm)}
                                    onChange={handleChange}
                                    onFocus={() => {
                                        setIsTagFocused(true);
                                        setIsInputClicked(true);
                                        setIsInputClickedOrTagSelected(true);
                                    }}
                                    ref={inputRef}
                                />
                            </div>
                        </div>
                        <div className={newsearchfilter.tagCount}>
                            {indexFocus && !isInputClicked ? navIndex + '/' + count : ''}
                            {/* {indexNumber}/{count} */}
                        </div>
                    </div>
                    <div className={newsearchfilter.clsSearchInputDiv}>
                        <div>
                            <Button
                                kind="link-default"
                                name="clear"
                                className={newsearchfilter.arrowBtn}
                                onClick={navigateUp}
                                disabled={!tagSearchTerm}
                            >
                                <ReactSVG src={ArrowUpBlueIcon} id={newsearchfilter.idAddBtn} />
                            </Button>
                        </div>
                        <div>
                            <Button
                                kind="link-default"
                                name="clear"
                                className={newsearchfilter.arrowBtn}
                                onClick={navigateDown}
                                disabled={!tagSearchTerm}
                            >
                                <ReactSVG src={ArrowDownBlueIcon} id={newsearchfilter.idAddBtn} />
                            </Button>
                        </div>
                        <div>
                            <Button
                                kind="link-default"
                                name="clear"
                                className={newsearchfilter.arrowBtn}
                                onClick={handleClearClick}
                                disabled={!tagSearchTerm}
                            >
                                <ReactSVG src={CloseBlueIcon} id={newsearchfilter.idAddBtn} />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            {isTagFocused && sortedSuggestions.length === 0 && (
                <ul className={newsearchfilter.clsAutoSearchList}>
                    <li

                        className={newsearchfilter.clsAutoSearchOption}

                    >
                        No Results Found
                    </li>

                </ul>
            )}

            {isTagFocused && tagSearchTerm.length > 1 && (
                <ul className={newsearchfilter.clsAutoSearchList}>
                    {sortedSuggestions.map((tag, index) => (
                        <li
                            key={index}
                            className={`${newsearchfilter.clsAutoSearchOption} ${index === activeSuggestionIndex ? newsearchfilter.activeSuggestion : ''}`}
                            onClick={() => handleTagClick(tag)}
                            ref={(el) => suggestionsRef.current[index] = el}
                        >
                            <div>{capitalizeFirstLetter(tag)}</div>
                            <div>{countTagOccurrences(tag)}</div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default FilterSearchBar;
