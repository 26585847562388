import classNames from "classnames";
import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap/lib";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFilterById,
  removeLocalFilter,
  selectNewFilter,
  selectShowAllEditOption,
  updateFilter,
  updateLocalFilter,
  selectLocalFilter
} from "../../../../../../data/search";
import newsearchfilter from "../../SearchFilterNew.scss";
import {
  PSScoreOptions,
  ForecloseFactorOptions,
  fieldTagsMap,
  formatDateShortWithUTC
} from "../../shared/ConstantsNew";
import IntelligenceIcon from '../../../../../../assets/images/propstream_intelligence_icon.png';
import { useFilter } from "../../TagFilterContext";
import FilterComboBox from "../../shared/Autosearch/FilterComboBox";
import { getBrandCode } from 'utils/brand';
import moment from "moment";


const PropstreamIntelligenceScreen = () => {
  const dispatch = useDispatch();
  const filter = useSelector(selectNewFilter);
  const getLocalFilter = useSelector(selectLocalFilter);
  const { selectedTags, filteredFields, navIndex } = useFilter();
  const [isFilterApplied, setFilterApplied] = useState(false);
  const refs = useRef({});

  const [psIntelligence, setIntelligenceScore] = useState({
    propConditionMin: null,
    propConditionMax: null,
    bathConditionMin: null,
    bathConditionMax: null,
    kitchenConditionMin: null,
    kitchenConditionMax: null,
    intConditionMin: null,
    intConditionMax: null,
    extConditionMin: null,
    extConditionMax: null,
    forecloseFactorMin: null,
    forecloseFactorMax: null
  });

  useEffect(() => {
    if (filter) {
      setIntelligenceScore(filter);
      setFilterApplied(true);
    }
  }, [filter]);

  useEffect(() => {
    if (isFilterApplied) {
      applyFiltersBasedOnPS();
    }
  }, [isFilterApplied]);

  const getBooleanOption = (value, options) => {
    return options.find(option => option.value === value) || null;
  };

  const applyFiltersBasedOnPS = () => {
    const fieldsToCheck = [
      // Range fields
      { label: "Overall Property Condition", min: psIntelligence.propConditionMin, max: psIntelligence.propConditionMax, key: "propCondition" },
      { label: "Bathroom Condition", min: psIntelligence.bathConditionMin, max: psIntelligence.bathConditionMax, key: "bathCondition" },
      { label: "Kitchen Condition", min: psIntelligence.kitchenConditionMin, max: psIntelligence.kitchenConditionMax, key: "kitchenCondition" },
      { label: "Interior Condition", min: psIntelligence.intConditionMin, max: psIntelligence.intConditionMax, key: "interiorCondition" },
      { label: "Exterior Condition", min: psIntelligence.extConditionMin, max: psIntelligence.extConditionMax, key: "exteriorCondition" },
      { label: "Foreclose Factor", min: psIntelligence.forecloseFactorMin, max: psIntelligence.forecloseFactorMax, key: "forecloseFactor" },
    ];

    fieldsToCheck.forEach(({ label, min, max, key, isBoolean, isText, isDate, value, options }) => {
      if (isBoolean) {
        if (value !== null && value !== '' && value !== undefined) {
          // handleBooleanSelectPCNew({value:value}, key, label);
          const option = getBooleanOption(value, options);
          if (option) {
            handleBooleanSelectPCNew(option, key, label);
          } else {
            // Handle the case where no valid option is found
            handleOldRemoveFilter(key);
            handleClearFilter(label);
          }
        }
      }
      else if (isText) {
        if (value !== null && value !== '' && value !== undefined) {
          inputValueChange(value, label, key);
        }
      }
      else if (isDate) {
        if ((min !== null && min !== '' && min !== undefined) || (max !== null && max !== '' && max !== undefined)) {
          comboDateValueChange(min, max, label, key);
        }
      }
      else {
        if ((min !== null && min !== '' && min !== undefined) || (max !== null && max !== '' && max !== undefined)) {
          comboBoxValueChange(min, max, label, key);
        }
      }
    });
  };

  const getEditOptions = useSelector(selectShowAllEditOption)
  useEffect(() => {
    if (getEditOptions) {
      if (getEditOptions?.key === 'psIntelligence') {
        let key = getEditOptions?.apiKey.replace(/Max|Min/g, "")
        editSectionClick(key ? key : '')
      }
    }
  }, [getEditOptions]);

  const editSectionClick = (id) => {
    setTimeout(() => {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 200); // 5000 milliseconds = 5 seconds
  };

  const handleOptionSelectPCNew = (option, key, leftLabel) => {
    updateFilterOldStore(key, option.value);
    updateFilterStore(option, key, leftLabel);
  };

  const handleSelectAllClick = (selectedOptions, leftValue, key) => {
    let name = "";
    if (selectedOptions?.length) {
      if (selectedOptions?.length > 1) {
        name = `${selectedOptions[0]?.name}  ( ${selectedOptions.length} )`;
      } else {
        name = `${selectedOptions[0]?.name}`;
      }

      const selectedIds = selectedOptions.map(element => element.id).join(",");
      const fullSeletedNamesList = selectedOptions
        .map(element => element.name)
        .join(",");
      updateFilterOldStore(key, selectedIds);
      // meed to work morning
      updateFilterStore(
        { value: key, label: name },
        key,
        leftValue,
        fullSeletedNamesList
      );
    } else {
      handleOldRemoveFilter(key);
      handleClearFilter(leftValue);
    }
  };

  const inputValueChange = (value, lable, key) => {
    updateFilterOldStore(key, value);
    updateFilterStoreCombox(value, key, lable);
  };

  // combox
  const comboBoxValueChange = (minValue, maxValue, leftLabel, key) => {
    let name;
    if (maxValue) {
      name = `${minValue} to ${maxValue}`;
    } else {
      name = `${minValue} or more`
    }

    const valuesToUpdate = [
      { key: `${key}Min`, value: minValue },
      { key: `${key}Max`, value: maxValue }
    ];
    valuesToUpdate.forEach(({ key, value }) => {
      updateFilterOldStore(key, value);
    });

    updateFilterStoreCombox(name, key, leftLabel);
  };


  // combo date
  const comboDateValueChange = (minValue, maxValue, leftLabel, key) => {
    let name;
    if (maxValue) {
      maxValue = moment(maxValue)
      if (minValue) {
        minValue = moment(minValue)
      }
      name = `${formatDateShortWithUTC(minValue)} to ${formatDateShortWithUTC(maxValue)}`;
    } else {
      minValue = moment(minValue)
      name = `${formatDateShortWithUTC(minValue)} or more`;
    }

    const valuesToUpdate = [
      { key: `${key}Min`, value: minValue },
      { key: `${key}Max`, value: maxValue }
    ];
    valuesToUpdate.forEach(({ key, value }) => {
      updateFilterOldStore(key, value);
    });

    updateFilterStoreCombox(name, key, leftLabel);
  };

  const handleBooleanSelectPCNew = (option, key, leftLabel) => {
    // add again
    if (option.value === true || option.value === false || option.value !== '') {
      updateFilterOldStore(key, option.value)
      updateFilterStore(option, key, leftLabel)
    } else {
      handleOldRemoveFilter(key)
      handleClearFilter(leftLabel)
    }

  };

  //  store
  // store
  const updateFilterOldStore = (key, id) => {
    // if (id) {
    dispatch(updateFilter({ [key]: id }));
    // }
  };

  const updateFilterStore = (option, key, leftLabel, fullSeletedNamesList) => {
    // if (option.value) {
    dispatch(
      updateLocalFilter([
        {
          key: "psIntelligence",
          id: leftLabel,
          name: option.label,
          apiKey: key,
          [key]: option.value,
          removeID: key,
          showALLDisplayValue: fullSeletedNamesList
        }
      ])
    );
    // }
  };

  const updateFilterStoreCombox = (name, key, leftLabel) => {
    // if (option.value) {
    dispatch(
      updateLocalFilter([
        {
          key: "psIntelligence", id: leftLabel, name: name, apiKey: key,
          removeID: key,
          showALLDisplayValue: name
        }
      ])
    );
    // }
  };

  const handleClearFilter = id => {
    dispatch(removeLocalFilter(id));
  };

  const handleOldRemoveFilter = id => {
    dispatch(removeFilterById(id));
  };

  const getClassNames = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    const psFilters = getLocalFilter.filter(filter => filter.key === 'psIntelligence');
    return `${newsearchfilter.clsShowOnTag}`;
  };

  useEffect(() => {
    const highlightedField = Object.keys(filteredFields).find(field => filteredFields[field] === navIndex);
    if (highlightedField && refs.current[highlightedField]) {
      refs.current[highlightedField].focus({ preventScroll: true });
      refs.current[highlightedField].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [filteredFields, navIndex]);

  const getTagsForField = (fieldLabel) => {
    return fieldTagsMap[fieldLabel] || [];
  };

  const isFilteredSection = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    return selectedTags.length > 0 && tagsForField.some(tag => selectedTags.includes(tag));
  };

  const brandCode = getBrandCode();

  return (
    <div className={classNames(
      getClassNames('Overall Property Condition'),
      getClassNames('Bathroom Condition'),
      getClassNames('Kitchen Condition'),
      getClassNames('Interior Condition'),
      getClassNames('Exterior Condition'),
      getClassNames('Foreclose Factor')
    )}>
      <div className={newsearchfilter.clsContentBody}>
        <div className={newsearchfilter.headerBg}>
          <h2 className={newsearchfilter.clsHeading}>
            {brandCode === 'propstream' ? 'PropStream Intelligence' : 'Property Intelligence'}
          </h2>
        </div>

        <h2 className={newsearchfilter.clsSubSecHeading}>
          Property Condition
        </h2>

        <div id="propCondition" className={classNames(newsearchfilter.pb_28, getClassNames("Overall Property Condition"), getClassNames("Bathroom Condition"))}
          ref={el => refs.current['Overall Property Condition'] = el}>
          <Row className={newsearchfilter.flexRow}>
            <Col md={6} xs={12} className={getClassNames("Overall Property Condition")}>
              <FilterComboBox
                boxTittle={<div><span><img className={newsearchfilter.inteliiIcon} src={IntelligenceIcon} alt="Intelligence" /></span>Overall Property Condition<span style={{ color: '#128292' }}>*</span></div>}
                minOptions={PSScoreOptions}
                maxOptions={PSScoreOptions}
                minLabel={"Min"}
                maxLabel={"Max"}
                isHighlightIndex={filteredFields['Overall Property Condition'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Overall Property Condition") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{
                  min: psIntelligence.propConditionMin,
                  max: psIntelligence.propConditionMax
                }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(
                    minValue,
                    maxValue,
                    "Overall Property Condition",
                    "propCondition"
                  )
                }
              />
            </Col>
            <Col md={6} xs={12} className={getClassNames("Bathroom Condition")}>
              <div className={newsearchfilter.propConditionInfoText}><span style={{ color: '#128292' }}>*</span>Overall Property Condition is an average of the four settings below and setting opposite extremes may cause a dramatic undesired reduction in results.</div>
            </Col>
          </Row>
        </div>
        <div id="bathCondition" className={classNames(newsearchfilter.pb_28, getClassNames("Bathroom Condition"), getClassNames("Kitchen Condition"))}
          ref={el => refs.current['Bathroom Condition'] = el}>
          <Row>
            <Col md={6} xs={12} className={getClassNames("Bathroom Condition")}>
              <FilterComboBox
                boxTittle={<div><span><img className={newsearchfilter.inteliiIcon} src={IntelligenceIcon} alt="Intelligence" /></span>Bathroom Condition</div>}
                minOptions={PSScoreOptions}
                maxOptions={PSScoreOptions}
                minLabel={"Min"}
                maxLabel={"Max"}
                isHighlightIndex={filteredFields['Bathroom Condition'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Bathroom Condition") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{
                  min: psIntelligence.bathConditionMin,
                  max: psIntelligence.bathConditionMax
                }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(
                    minValue,
                    maxValue,
                    "Bathroom Condition",
                    "bathCondition"
                  )
                }
              />
            </Col>
            <Col md={6} xs={12} className={getClassNames("Kitchen Condition")}>
              <div id="kitchenCondition" ref={el => refs.current['Kitchen Condition'] = el}></div>
              <FilterComboBox
                boxTittle={<div><span><img className={newsearchfilter.inteliiIcon} src={IntelligenceIcon} alt="Intelligence" /></span>Kitchen Condition</div>}
                minOptions={PSScoreOptions}
                maxOptions={PSScoreOptions}
                minLabel={"Min"}
                maxLabel={"Max"}
                isHighlightIndex={filteredFields['Kitchen Condition'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Kitchen Condition") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{
                  min: psIntelligence.kitchenConditionMin,
                  max: psIntelligence.kitchenConditionMax
                }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(
                    minValue,
                    maxValue,
                    "Kitchen Condition",
                    "kitchenCondition"
                  )
                }
              />
            </Col>
          </Row>
        </div>
        <div id="interiorCondition" className={classNames(newsearchfilter.pb_28, getClassNames("Interior Condition"), getClassNames("Exterior Condition"))}
          ref={el => refs.current['Interior Condition'] = el}>
          <Row>
            <Col md={6} xs={12} className={getClassNames("Interior Condition")}>
              <FilterComboBox
                boxTittle={<div><span><img className={newsearchfilter.inteliiIcon} src={IntelligenceIcon} alt="Intelligence" /></span>Interior Condition</div>}
                minOptions={PSScoreOptions}
                maxOptions={PSScoreOptions}
                minLabel={"Min"}
                maxLabel={"Max"}
                isHighlightIndex={filteredFields['Interior Condition'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Interior Condition") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{
                  min: psIntelligence.intConditionMin,
                  max: psIntelligence.intConditionMax
                }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(
                    minValue,
                    maxValue,
                    "Interior Condition",
                    "intCondition"
                  )
                }
              />
            </Col>
            <Col md={6} xs={12} className={getClassNames("Exterior Condition")}>
              <div id="exteriorCondition" ref={el => refs.current['Exterior Condition'] = el}></div>
              <FilterComboBox
                boxTittle={<div><span><img className={newsearchfilter.inteliiIcon} src={IntelligenceIcon} alt="Intelligence" /></span>Exterior Condition</div>}
                minOptions={PSScoreOptions}
                maxOptions={PSScoreOptions}
                minLabel={"Min"}
                maxLabel={"Max"}
                isHighlightIndex={filteredFields['Exterior Condition'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Exterior Condition") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{
                  min: psIntelligence.extConditionMin,
                  max: psIntelligence.extConditionMax
                }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(
                    minValue,
                    maxValue,
                    "Exterior Condition",
                    "extCondition"
                  )
                }
              />
            </Col>
          </Row>
        </div>

        <div
          className={classNames(
            newsearchfilter.clsBorderTop
          )}
        ></div>

        <h2 className={newsearchfilter.clsSubSecHeading}>
          Foreclose Factor
        </h2>
        <div id="forecloseFactor" className={classNames(newsearchfilter.pb_28, getClassNames("Foreclose Factor"))}
          ref={el => refs.current['Foreclose Factor'] = el}>
          <Row>
            <Col md={6} xs={12} className={getClassNames("Foreclose Factor")}>
              <FilterComboBox
                boxTittle={<div><span><img className={newsearchfilter.inteliiIcon} src={IntelligenceIcon} alt="Intelligence" /></span>Foreclose Factor</div>}
                minOptions={ForecloseFactorOptions}
                maxOptions={ForecloseFactorOptions}
                minLabel={"Min"}
                maxLabel={"Max"}
                isHighlightIndex={filteredFields['Foreclose Factor'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Foreclose Factor") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{
                  min: psIntelligence.forecloseFactorMin,
                  max: psIntelligence.forecloseFactorMax
                }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(
                    minValue,
                    maxValue,
                    "Foreclose Factor",
                    "forecloseFactor"
                  )
                }
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default PropstreamIntelligenceScreen;
