

import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import formatDate from 'utils/date/formatDate';
import 'react-datepicker/dist/react-datepicker.css';
import css from './style.scss';
import PropTypes from 'prop-types';
import classNames from "classnames";
import CalendarIcon from '../../../../../../assets/images/calendar-icon.svg';
import CalendarArrow from '../../../../../../assets/images/datepicker-arrow.svg';
import { ReactSVG } from "react-svg";
import FilterYearDropdown from '../YearDropdown';
import Button from "components/base/Button";
import { getMonth, getYear } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_FORMAT = 'MMM dd, yyyy';


const CustomDatePicker = ({ value, name, placeholder, dateFormat = DEFAULT_FORMAT, onChange, noIcon, ...rest }) => {
  const [selectedDate, setSelectedDate] = useState(value);

  const handleChange = (date, event) => {
    setSelectedDate(date);
    if (onChange) {
      onChange(date);
    }
  };

  const renderIcon = () => {
    return !noIcon && <span className={css.icon}>
      {/* <ReactSVG src={CalendarIcon} /> */}
      <img src={CalendarIcon} />
    </span>;
  };

  const formattedValue = value ? formatDate(value, '', false, dateFormat) : null;


  const CustomHeader = ({ date,  changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => {
    let currentMonth = date.toLocaleDateString('default', { month: 'long' });
    {formatDate(new Date(date), DEFAULT_FORMAT)}
    const currentYear = date.getFullYear();
    const years = Array.from({ length: currentYear - 1990 + 1 }, (_, index) => currentYear - index);
  
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    const handleTodayClick = () => {
      const today = new Date();
      setSelectedDate(today);
      if (onChange) {
        onChange(today);
      }
      // Update the month and year
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();
      changeMonth(currentMonth);
      changeYear(currentYear);
      
    };


    return (
      <div className={css.clsCustomHeader}>
        <div className={css.clsTopHeader}>
          <button className={classNames(css.clsArrows, css.clsLeftArrow)} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            <img src={CalendarArrow} alt="Previous Month" />
          </button>
          <span className={css.clsDateTxt}>
            <FilterYearDropdown
              options={months}
              defaultOption={months[getMonth(date)]}
              onSelect={(selectedOption) => {
                changeMonth(months.indexOf(selectedOption))
              }}
            />
            <FilterYearDropdown
              options={years}
              defaultOption={getYear(date)}
              onSelect={(selectedOption) => changeYear(selectedOption)}
            />
          </span>
          <button className={classNames(css.clsArrows, css.clsRightArrow)} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            <img src={CalendarArrow} alt="Next Month" />
          </button>
        </div>
        <div className={css.clsBotHeader}>
          <Button id={'newuifilterRef'+ uuidv4()} kind="link-default" className={css.clsTodayButton} size="large" onClick={() => handleTodayClick()}>
            Today
          </Button>
        </div>
      </div>
    );
  };

  const MyContainer = ({ className, children }) => (
    <div className={css.myContainer}>
      <div id={'newuifilterRef'+ uuidv4()}  style={{ position: 'relative' }}>{children}</div>
    </div>
  );

  return (
    <div className={(css.root, css.clsFillterDatePickerBox)}>
      <div className="clsFillterDatePicker">
        <DatePicker
        id={'newuifilterRef'+ uuidv4()}
          // selected={selectedDate}
          onChange={handleChange}
          name={name}
          placeholderText={placeholder}
          dateFormat={dateFormat}
          autoComplete="off"
          utcOffset={0}
          calendarContainer={MyContainer}
          renderCustomHeader={({ date,  changeYear, changeMonth,decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
            <>
             <CustomHeader
              date={date}
              changeYear={changeYear}
              changeMonth={changeMonth}
              decreaseMonth={decreaseMonth}
              increaseMonth={increaseMonth}
              prevMonthButtonDisabled={prevMonthButtonDisabled}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
            />
            </>
          )}
  
          // renderCustomHeader={CustomHeader}
          customInput={<input className={css.input} />}
          {...rest}
        />
        {renderIcon()}
      </div>
    </div>
  );
};

CustomDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  noIcon: PropTypes.bool,
  dateFormat: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.instanceOf(Date),
};

// CustomDatePicker.defaultProps = {
//   dateFormat: DEFAULT_FORMAT,
// };

export default CustomDatePicker;